<template>
  <div class="app-admin-wrap layout-sidebar-large clearfix">
    <TopNav />
    <LeftNav />
    <!-- ============ Body content start ============= -->
    <div class="main-content-wrap sidenav-open d-flex flex-column">
      <div class="main-content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import TopNav from "../components/Navigation/TopNav";
import LeftNav from "../components/Navigation/LeftNav";
export default {
  components: {
    TopNav,
    LeftNav
  }
};
</script>

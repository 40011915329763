<template>
  <div>
    <!-- <div class="side-content-wrap"> -->
    <div
      class="sidebar-left open rtl-ps-none"
      data-perfect-scrollbar
      data-suppress-scroll-x="true"
    >
      <ul class="navigation-left">
        <li :class="[{ active: $route.name === 'routers' }, 'nav-item']">
          <router-link class="nav-item-hold" :to="{ name: 'routers' }">
            <i class="nav-icon i-Library"></i>
            <span class="nav-text">Routers</span>
          </router-link>
          <div class="triangle"></div>
        </li>
        <li :class="[{ active: $route.name === 'kiosks' }, 'nav-item']">
          <router-link class="nav-item-hold" :to="{ name: 'kiosks' }">
            <i class="nav-icon i-Suitcase"></i>
            <span class="nav-text">Kiosks</span>
          </router-link>
          <div class="triangle"></div>
        </li>
        <li :class="[{ active: $route.name === 'support' }, 'nav-item']">
          <router-link class="nav-item-hold" :to="{ name: 'support' }">
            <i class="nav-icon i-Ambulance"></i>
            <span class="nav-text">Support</span>
          </router-link>
          <div class="triangle"></div>
        </li>
        <li :class="[{ active: $route.name === 'profile' }, 'nav-item']">
          <router-link class="nav-item-hold" :to="{ name: 'profile' }">
            <i class="nav-icon i-Administrator"></i>
            <span class="nav-text">Account</span>
          </router-link>
          <div class="triangle"></div>
        </li>
      </ul>
    </div>
    <div class="sidebar-overlay"></div>
  </div>
  <!-- </div> -->
</template>

<script>
export default {
  name: "LeftNav"
};
</script>

import "@/assets/fonts/fontawesome-free-5.10.1-web/css/all.css";
// Import global css
import "@/assets/styles/css/themes/lite-purple.min.css";
import "@/assets/styles/css/translator.css";
import "@/assets/styles/vendor/perfect-scrollbar.css";
// import axios from "axios";
import BootstrapVue from "bootstrap-vue";
import dotenv from "dotenv";
import IdleVue from "idle-vue";
import "sweetalert2/dist/sweetalert2.min.css";
import VeeValidate from "vee-validate";
import Vue from "vue";
import VueGoodTablePlugin from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import VueHtmlToPaper from "vue-html-to-paper";
import VueSweetalert2 from "vue-sweetalert2";
import VueTheMask from "vue-the-mask";
import App from "./App.vue";
import setUp from "./helpers/interceptors";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
dotenv.config();

setUp();
Vue.use(require("vue-moment"));
Vue.config.productionTip = false;

const swalOptions = {
  confirmButtonColor: "#348f6a",
  cancelButtonColor: "#ff7674"
};
Vue.use(VueTheMask);
Vue.use(BootstrapVue);
Vue.use(VeeValidate);
Vue.use(VueGoodTablePlugin);
Vue.use(VueSweetalert2, swalOptions);

const eventsHub = new Vue();
const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"]
};
Vue.use(VueHtmlToPaper, options);

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 1000 * 60 * 10, // 10 minutes,
  startAtIdle: false
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

import Vue from "vue";
import Router from "vue-router";
import AuthLayout from "@/layout/AuthLayout";
import DashboardLayout from "@/layout/DashboardLayout";
import store from "./store";

const router = new Router({
  // linkExactActiveClass: 'active',
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "routers",
      meta: {
        requiresAuth: true
      },
      component: DashboardLayout,
      children: [
        {
          path: "/dashboard",
          name: "index",
          component: () => import("./pages/index")
        },
        {
          path: "/routers",
          name: "routers",
          component: () => import("./pages/routers/index")
        },
        {
          path: "/routers/new",
          name: "new-router",
          component: () => import("./pages/routers/newRouter")
        },
        {
          path: "/kiosks",
          name: "kiosks",
          component: () => import("./pages/kiosks/index")
        },
        {
          path: "/kiosks/new",
          name: "new-kiosk",
          component: () => import("./pages/kiosks/newKiosk")
        },
        {
          path: "/support",
          name: "support",
          component: () => import("./pages/support")
        },
        {
          path: "/support/new",
          name: "new-support",
          component: () => import("./pages/support/new")
        },
        {
          path: "/support/:id",
          name: "support-details",
          component: () => import("./pages/support/_id/index")
        },
        {
          path: "/profile",
          name: "profile",
          component: () => import("./pages/profile/index")
        }
      ]
    },
    {
      path: "/",
      redirect: "login",
      component: AuthLayout,
      children: [
        {
          path: "/login",
          name: "login",
          component: () => import("./pages/login")
        },
        {
          path: "/password/reset",
          name: "password-reset",
          component: () => import("./pages/password/reset/index")
        },
        {
          path: "/2fa",
          name: "2fa",
          component: () => import("./pages/2fa")
        }
      ]
    }
  ]
});

router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title =
    toRoute.meta && toRoute.meta.title
      ? toRoute.meta.title
      : "Ared Manufacturer Dashboard";
  if (toRoute.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      next({
        path: "/login",
        query: {
          redirect: toRoute.fullPath
        }
      });
    } else {
      const user = store.getters.loggedInUser;
      if (user) {
        const allowed_roles = ["ared_admin", "Manufacturer", "partner_staff"];
        if (!allowed_roles.includes(user.role)) {
          store.commit("clearUserData");
          next({
            path: "/login",
            query: {
              redirect: toRoute.fullPath
            }
          });
        }
      }
      next();
    }
  } else {
    next();
  }
});

export default router;

Vue.use(Router);

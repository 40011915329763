import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "./auth";
import createPlugin from 'logrocket-vuex';
import LogRocket from 'logrocket';
LogRocket.init('edmslm/ared');
const logrocketPlugin = createPlugin(LogRocket);

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth
  },
  plugins: [createPersistedState(),logrocketPlugin],
  state: {},
  mutations: {},
  actions: {},
  getters: {
    getBreadcrumb: state => state.auth.breadcrumb_details,
    getUserDetails: state => state.auth.userDetails
  }
});

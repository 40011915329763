<template>
  <div>
    <!-- ============ Compact Layout start ============= -->
    <!-- ============Deafult  Large SIdebar Layout start ============= -->

    <div class="main-header">
      <div class="ml-2">
        <img src="@/assets/logo.png" alt="ared-logo" style="height:3.4em;" />
      </div>

      <div v-if="isAuthenticated" class="menu-toggle text-white">
        <div></div>
        <div></div>
        <div></div>
      </div>

      <h3 class="text-bold text-white text-uppercase">Ared Manufacturers</h3>

      <div style="margin: auto"></div>

      <div class="header-part-right">
        <!-- Full screen toggle -->
        <!-- <i
          class="i-Full-Screen header-icon d-none d-sm-inline-block"
          data-fullscreen
        ></i> -->

        <!-- User avatar dropdown -->
        <div v-if="user" class="dropdown">
          <div class="col align-self-end">
            <span class="text-uppercase">
              <span class="mr-2 text-white btn">
                <router-link
                  :to="{ name: 'profile' }"
                  class="text-white text-decoration-none"
                >
                  <i class="i-Lock-User mr-1"></i> {{ loggedInUser.first_name }}
                  {{ loggedInUser.last_name }}
                </router-link>
              </span>
              <span class="mr-3 text-white btn" @click="logout">Sign out</span>
              <b-button
                class="mr-3 text-white"
                variant="success"
                size="sm"
                v-b-modal.translate-modal
              >
                Translate
              </b-button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- header top menu end -->
    <TranslateModal />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import router from "@/router";
import TranslateModal from "./TranslateModal";

export default {
  name: "TopNav",
  components: { TranslateModal },
  data() {
    return {
      activeNotifications: false,
      user: {}
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated", "loggedInUser"]),
    isIdle() {
      return this.$store.state.idleVue.isIdle;
    }
  },
  methods: {
    ...mapMutations(["clearUserData"]),
    logout() {
      this.clearUserData();
      router.push({ path: "/login" });
    }
  }
};
</script>

import dotenv from "dotenv";
import axios from "axios";

import store from "../store";
import router from "../router";

dotenv.config();

export default function setUp() {
  let apiBaseUrl;

  switch (process.env.VUE_APP_ENV) {
    case "dev":
      apiBaseUrl = process.env.VUE_APP_DEV_API_BASE_URL;
      break;
    case "qa":
      apiBaseUrl = process.env.VUE_APP_QA_API_BASE_URL;
      break;
    case "uat":
      apiBaseUrl = process.env.VUE_APP_UAT_API_BASE_URL;
      break;
    case "prod":
      apiBaseUrl = process.env.VUE_APP_PROD_API_BASE_URL;
      break;
    default:
      apiBaseUrl = "http://localhost:8000/api/";
  }

  axios.defaults.baseURL = apiBaseUrl;

  // Add a request interceptor
  axios.interceptors.request.use(
    function(config) {
      // Do something before request is sent
      const token = store.getters.accessToken;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    function(error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    function(response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    async function(error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      const originalRequest = error.config;
      if (
        error.response.status === 401 &&
        originalRequest.url.includes("auth/jwt/refresh/")
      ) {
        store.commit("clearUserData");
        router.push("/login");
        return Promise.reject(error);
      } else if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        await store.dispatch("refreshToken");
        return axios(originalRequest);
      }
      return Promise.reject(error);
    }
  );
}
